@tailwind base;
@tailwind components;
@tailwind utilities;


@import 'swiper/swiper-bundle.css';
@import url('https://fonts.googleapis.com/css2?family=Cormorant+SC:wght@300;400;500;600;700&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');


body {
    background-color: #ffffff;
    font-family: "Raleway", sans-serif;
    font-weight: 400;
    font-style: normal;
  }

.cormorant {
    font-family: "Cormorant SC", serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
  }

.swiper {
    position: relative;
}

  .swiper-pagination {
    width: auto;
    height: 24px;
}

.swiper-pagination-bullet {
    background: #74B4CE !important;
    opacity: 1;
    height: 8px;
    width: 8px;
}

.swiper-pagination-bullet-active {
    background: #44718B !important;
}

/* Custom navigation arrow style */
.swiper-button-next,
.swiper-button-prev {
    color: #44718B !important;
    z-index: 10;
}

@media (max-width: 1024px) {
  .swiper-button-next,
  .swiper-button-prev {
    display: none !important;
  }
}

